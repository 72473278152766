import React from 'react';
import { mq, colors } from '../../theme';
import Image from '../Image';

const PlayButton = ({ ...rest }) => {
  return (
    <div
      {...rest}
      css={mq({
        cursor: 'pointer',
        position: 'absolute',
        content: '""',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        backgroundColor: colors.neutralBlack30,
        boxShadow: `0px 3px 8px 0 ${colors.neutralBlack10}`,
        justifyContent: 'center',
        alignItems: 'center'
      })}
    >
      <Image
        src="play.svg"
        css={{
          width: 60,
          transition: 'width 200ms ease',
          ':hover': {
            width: 70
          }
        }}
      />
    </div>
  );
};

export default PlayButton;
